import React from "react"

const OurStory = ({}) => (
  <section id="our-story">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-offset-2 col-md-8 text-center">
          <div className="section-title animation fadeInUp">
            <h2>
              Our <img src="images/heart-icon.png" alt="" /> Story
            </h2>

            <p>We met in Athens 6 years ago</p>
          </div>
        </div>
      </div>

      <div className="row story-row">
        <div className="col-sm-12 col-md-offset-1 col-md-4 text-center">
          <div className="photo-item frame-border animation delay1 fadeInLeft">
            <img
              src="/images/our-story-taf.png"
              alt=""
              className="hover-animation image-zoom-in"
            />

            <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

            <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
              <div className="alignment">
                <div className="v-align center-middle">
                  <a
                    href="/images/our-story-taf.png"
                    className="magnific-zoom"
                    title="Our First Date"
                  >
                    <div className="de-icon circle light medium-size inline">
                      <i className="de-icon-zoom-in"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-2 text-center story-date-wrapper animation fadeIn">
          <div className="arrow-left"></div>
          <div className="story-date">
            <div className="date-only">05</div>
            <div className="month-year">2016</div>
          </div>
          <div className="arrow-right"></div>
          <div className="clearboth"></div>
        </div>

        <div className="col-sm-12 col-md-4 animation delay1 fadeInRight">
          <h3>Our First Date</h3>
          <p>
            Our first date was at TAF (The Art Foundation) and we hit it off
            right away.
          </p>
        </div>

        <div className="vertical-line"></div>
      </div>

      <div className="row story-row">
        <div className="col-sm-12 col-md-4 col-md-push-6 text-center">
          <div className="photo-item frame-border animation delay1 fadeInLeft">
            <img
              src="/images/sf.jpeg"
              alt=""
              className="hover-animation image-zoom-in"
            />

            <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

            <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
              <div className="alignment">
                <div className="v-align center-middle">
                  <a
                    href="/images/sf.jpeg"
                    className="magnific-zoom"
                    title="Love Ring"
                  >
                    <div className="de-icon circle light medium-size inline">
                      <i className="de-icon-zoom-in"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-2 text-center story-date-wrapper animation fadeIn">
          <div className="arrow-left"></div>
          <div className="story-date">
            <div className="date-only">04</div>
            <div className="month-year">2018</div>
          </div>
          <div className="arrow-right"></div>
          <div className="clearboth"></div>
        </div>

        <div className="col-sm-12 col-md-4 col-md-pull-6 animation delay1 fadeInRight">
          <h3>Moved to the US</h3>
          <p>Just two years later, San Francisco became our new home</p>
        </div>

        <div className="vertical-line"></div>
      </div>

      <div className="row story-row">
        <div className="col-sm-12 col-md-4 col-md-push-6 text-center">
          <div className="photo-item frame-border animation delay1 fadeInLeft">
            <img
              src="/images/our-story-hawaii.jpg"
              alt=""
              className="hover-animation image-zoom-in"
            />

            <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

            <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
              <div className="alignment">
                <div className="v-align center-middle">
                  <a
                    href="/images/our-story-hawaii.jpg"
                    className="magnific-zoom"
                    title="Love Ring"
                  >
                    <div className="de-icon circle light medium-size inline">
                      <i className="de-icon-zoom-in"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-2 text-center story-date-wrapper animation fadeIn">
          <div className="arrow-left"></div>
          <div className="story-date">
            <div className="date-only">09</div>
            <div className="month-year">2019</div>
          </div>
          <div className="arrow-right"></div>
          <div className="clearboth"></div>
        </div>

        <div className="col-sm-12 col-md-4 col-md-pull-6 animation delay1 fadeInRight">
          <h3>He Proposed</h3>
          <p>Bill proposed in Hawaii, at Turtle Beach</p>
        </div>

        <div className="vertical-line"></div>
      </div>

      <div className="row story-row">
        <div className="col-sm-12 col-md-4 col-md-push-6 text-center">
          <div className="photo-item frame-border animation delay1 fadeInLeft">
            <img
              src="/images/fozzie.jpg"
              alt=""
              className="hover-animation image-zoom-in"
            />

            <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

            <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
              <div className="alignment">
                <div className="v-align center-middle">
                  <a
                    href="/images/fozzie.jpg"
                    className="magnific-zoom"
                    title="Love Ring"
                  >
                    <div className="de-icon circle light medium-size inline">
                      <i className="de-icon-zoom-in"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-2 text-center story-date-wrapper animation fadeIn">
          <div className="arrow-left"></div>
          <div className="story-date">
            <div className="date-only">02</div>
            <div className="month-year">2020</div>
          </div>
          <div className="arrow-right"></div>
          <div className="clearboth"></div>
        </div>

        <div className="col-sm-12 col-md-4 col-md-pull-6 animation delay1 fadeInRight">
          <h3>Welcome Fozzie!</h3>
          <p>
            {" "}
            Right before the pandemic, we adopted Fozzie and became a family of
            3
          </p>
        </div>

        <div className="vertical-line"></div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <div id="end-of-story" className="animation fadeInUp">
            <h2>AND THIS SUMMER... WE ARE GETTING MARRIED</h2>
            <div className="heart-divider">
              <span className="grey-line"></span>
              <i className="de-icon-heart pink-heart"></i>
              <i className="de-icon-heart grey-heart"></i>
              <span className="grey-line"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

OurStory.propTypes = {}

OurStory.defaultProps = {}

export default OurStory
